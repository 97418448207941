
.tw-panel-container {
	display: flex;
	width: 100%;
	flex: 1;
	overflow: hidden;
	background-color: var(--tw-color-background-primary);
}

.tw-panel {
	position: relative;
	
	&:last-child {
		flex: 1;
		padding: var(--tw-size-padding-small);
		background-color: var(--tw-color-background-tertiary);
	}
}

.tw-resizer {
	width: 1rem;
	// background-color: var(--tw-color-border-tertiary);
	position: absolute;
	cursor: col-resize;
	height: 100%;
	top: 0;
	flex-shrink: 0;
	z-index: 99;
	user-select: none;
	margin-left: -0.5rem;
	
	&::after {
		content: '';
		position: absolute;
		width: 2px;
		top: 0;
		bottom: 0;
		left: 50%;
		margin-left: -1px;
		background-color: var(--tw-color-border-primary);
	}
	
	&:hover::after {
		background-color: var(--tw-color-border-active);
		box-shadow: 0 0 0 2px var(--tw-color-border-active);
	}
}

.editor-pane {
	height: 100%;
	display: flex;
	flex-direction: column;
}


.preview-pane {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	
	& > .vscode {
		flex: 1;
	}
}

.preview-pane-controls {
	margin-bottom: var(--tw-size-padding-small);
	display: flex;
	flex-direction: row;
	& .tw-radio {
		margin-left: var(--tw-size-padding-small);
	}
}

.vscode {
	height: 100%;
	width: 100%;
	position: relative;
	box-shadow: 0 0 1rem var(--tw-color-shadow-primary),
		0 0 0.25rem var(--tw-color-shadow-primary);
	outline-width: 1px;
	outline-style: solid;
	outline-color: transparent;
	outline-color: var(--application-window-inactiveBorder, #0000);
}

.vscode:focus,
.vscode:focus-within,
.vscode:active {
	outline-color: var(--application-window-activeBorder, #0000);
}
