.tw-header {
	height: var(--tw-size-height-nav);
	border-bottom: var(--tw-border-primary);
	background-color: var(--tw-color-background-primary);
	// background: linear-gradient(90deg, var(--base-primary-100), var(--base-secondary-100));
	z-index: 100;
	display: flex;
	flex-direction: row;
	align-items: center;
	
	&.secondary {
		z-index: 10;
	}
	
	& .tw-input {
		height: auto;
		font-size: var(--tw-size-font-small);
		padding: var(--tw-size-padding-xs) var(--tw-size-padding-small);
		min-width: 15rem;
	}
	
	& .tw-input-with-button {
		margin: 0;
	}
	
	& label {
		margin: 0 var(--tw-size-padding-small) 0 0;
	}
}

.tw-header-primary-nav {
	flex: 1;
	display: flex;
	align-items: center;
}

.tw-header-secondary-nav {
	align-self: flex-end;
	display: flex;
	align-items: center;
	height: var(--tw-size-height-nav);
	
	& > * {
		margin-right: var(--tw-size-padding-base);
	}
}

.tw-header-nav-item {
	line-height: var(--tw-size-height-nav);
	height: var(--tw-size-height-nav);
	display: inline-block;
	padding: 0 var(--tw-size-padding-base);
	border-radius: 0;
	border-width: 0 0 var(--tw-size-border-base) 0;
	border-color: transparent;
	box-sizing: border-box;
	vertical-align: top;
	color: var(--tw-color-font-nav);
	font-weight: bold;
	
	&:hover {
		box-shadow: none;
	}
	
	&.active {
		color: var(--tw-color-font-nav-active);
		border-color: var(--tw-color-font-nav-active);
	}
}


.github-corner {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 999;
	display: block;
}

.github {
	width: 1.5rem;
	height: 1.5rem;
	vertical-align: middle;
}

.github-path {
	fill: var(--tw-color-font-quaternary);
	
	.github:hover > & {
		fill: var(--tw-color-font-tertiary);
	}
}

.tw-header-secondary-nav > button.primary {
	padding: var(--tw-size-padding-tiny) var(--tw-size-padding-large);
	margin-right: var(--tw-size-padding-base);
}
