.monaco-workbench {
	font-family: -apple-system,BlinkMacSystemFont,sans-serif;
}

.vscode-element-tooltip {
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--tw-color-background-primary);
	padding: var(--tw-size-padding-base);
	color: var(--tw-color-font-primary);
}
