.checkbox-group {
	display: flex;
	flex-direction: row;
	margin: var(--tw-size-padding-small) 0;
}

.checkbox-group > .checkbox {
	flex: 1;
}

.checkbox-input {
	opacity: 0;
	padding: 0;
	margin: 0;
	width: 0;
	height: 0;
}

.checkbox-label {
	position: relative;
	display: inline-block;
	padding-left: 2rem;
	margin: 0;
	
	&::before,
	&::after {
		position: absolute;
		content: "";
		
		/*Needed for the line-height to take effect*/
		display: inline-block;
	}
	
	/* Outer box of the fake checkbox */
	&::before {
		height: 1.25rem;
		width: 1.25rem;
		border: var(--tw-border-secondary);
		border-radius: var(--tw-size-border-radius);
		left: 0;
		top: 0.125rem;
	}
	
	/* Checkmark of the fake checkbox */
	&::after {
		height: 0.5rem;
		width: 0.8rem;
		border-left: 2px solid #fff;
		border-bottom: 2px solid #fff;
		transform: rotate(-45deg);
		left: 0.25rem;
		top: 0.375rem;
		content: none;
	}
	
	/* Unhide on the checked state */
	.checkbox-input:checked + &::after {
		content: "";
	}
	
	.checkbox-input:checked + &::before {
		background-color: var(--tw-color-brand-primary-1);
		border-color: transparent;
	}
	
	/* Adding focus styles on the outer-box of the fake checkbox */
	.checkbox-input:focus + &::before {
		outline-style: solid;
		outline-offset: 2px;
		outline-width: 2px;
		outline-color: var(--tw-color-outline);
	}
}
