.tw-radio-grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.tw-radio-grid-item {
	padding-right: var(--tw-size-padding-small);
	padding-bottom: var(--tw-size-padding-small);
	margin: 0;
}

.tw-radio-input {
	opacity: 0;
	padding: 0;
	margin: 0;
	width: 0;
	height: 0;
}

.tw-radio-label {
	position: relative;
	display: inline-block;
	padding-left: 1.5rem;
	margin: 0;
	
	&::before,
	&::after {
		position: absolute;
		content: "";
		
		/*Needed for the line-height to take effect*/
		display: inline-block;
	}
	
	/* Outer box of the fake checkbox */
	&::before {
		height: 1.25rem;
		width: 1.25rem;
		border: var(--tw-border-primary);
		border-radius: 1.25rem;
		background-color: var(--tw-color-background-primary);
		left: 0;
		top: 0.125rem;
	}
	
	/* Checkmark of the fake checkbox */
	&::after {
		height: 0.4rem;
		width: 0.6rem;
		border-left: 2px solid #fff;
		border-bottom: 2px solid #fff;
		transform: rotate(-45deg);
		left: 0.325rem;
		top: 0.5rem;
		content: none;
	}
	
	/* Unhide on the checked state */
	.tw-radio-input:checked + &::after {
		content: "";
	}
	
	.tw-radio-input:checked + &::before {
		background-color: var(--tw-color-border-focus);
		border-color: transparent;
	}
	
	/* Adding focus styles on the outer-box of the fake checkbox */
	.tw-radio-input:focus + &::before,
	.tw-radio-input:hover + &::before {
		border-color: var(--tw-color-border-focus);
	}
	
	.tw-radio-input:disabled + & {
		color: var(--tw-color-font-tertiary);
	}
	
	.tw-radio-input:disabled + &::before {
		opacity: 0.5;
	}
}

.color-radio {
	.tw-radio-label {
		height: 2rem;
		width: 2rem;
		margin-right: var(--tw-size-padding-small);
	}
	
	.tw-radio-label::before {
		border: none;
		width: 2rem;
		height: 2rem;
		border-radius: 2rem;
		top: 0;
	}
	
	.tw-radio-label::after {
		content: "";
		left: -0.25rem;
		top: -0.25rem;
		height: 2.5rem;
		width: 2.5rem;
		border: var(--tw-border-tertiary);
		border-radius: 5rem;
	}
	
	.tw-radio-input:focus + .tw-radio-label::after,
	.tw-radio-input:hover + .tw-radio-label::after {
		border-color: var(--tw-color-border-active);
	}
	
	.tw-radio-input:checked + .tw-radio-label::after {
		border-color: var(--tw-color-border-active);
		box-shadow: 0 0 0 2px var(--tw-color-border-active);
	}
}

$colors: "neutral", "red", "orange", "yellow", "lime", "green", "teal", "blue", "purple", "pink";

@each $color in $colors {
	.tw-radio.#{$color} { .tw-radio-label::before { background-color: var(--base-#{$color}-90); } }
}
