.tw-search {
	position: sticky;
	top: 0;
	z-index: 10;
	// background-color: var(--tw-color-background-primary);
	// background: linear-gradient(to bottom, #fff 0%, #fff 75%, rgba(255,255,255, 0) 100%);
	padding: var(--tw-size-padding-base) 0;
}

.tw-search-input {
	border-radius: 2rem;
	width: 100%;
	padding: var(--tw-size-padding-small) var(--tw-size-padding-base);
	border: var(--tw-border-primary);
	box-shadow: var(--tw-shadow-secondary);
	
	&:focus {
		border-color: var(--tw-color-border-primary);
	}
}

.tw-search-clear {
	position: absolute;
	border: none;
	width: 1.5rem;
	height: 1.5rem;
	right: 0.5rem;
	top: 50%;
	margin-top: -0.75rem;
	// line-height: 1.5rem;
	border-radius: 1.5rem;
	padding: 0;
	background-color: var(--tw-color-border-secondary);
	color: #fff;
	
	&:hover {
		background-color: var(--tw-color-border-primary);
		color: #fff;
	}
	
	& > .codicon {
		font-weight: bold;
		font-size: 1.25rem;
	}
}
