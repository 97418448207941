.sidebar {
	height: 100%;
	background-color: var(--application-sideBar-background);
	color: var(--application-sideBar-foreground);
	border-right: 1px solid var(--application-sideBar-border);
}

.activitybar {
	width: 48px;
	background-color: var(--application-activityBar-background);
	border-right: 1px solid var(--application-activityBar-border);
	height: 100%;
}

.monaco-workbench .activitybar > .content :not(.monaco-menu) > .monaco-action-bar .action-item.checked .active-item-indicator:before {
	/* if activityBar.activeBorder isn't set, vscode uses activityBar.foreground */
	border-left-color: var(--application-activityBar-activeBorder, var(--application-activityBar-foreground));
}

.monaco-workbench .activitybar > .content :not(.monaco-menu) > .monaco-action-bar .action-item.checked:focus .active-item-indicator::before {
	visibility: visible;
}

.monaco-action-bar .action-item.checked {
	background-color: var(--application-activityBar-activeBackground);
}

.activitybar .monaco-action-bar .badge {
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.activitybar .monaco-action-bar .badge .badge-content {
	position: absolute;
	top: 24px;
	right: 8px;
	font-size: 9px;
	font-weight: 600;
	min-width: 8px;
	height: 16px;
	line-height: 16px;
	padding: 0 4px;
	border-radius: 20px;
	text-align: center;
	background-color: var(--application-activityBarBadge-background);
	color: var(--application-activityBarBadge-foreground);
}

.badge-content {
	background-color: var(--application-badge-background);
	color: var(--application-badge-foreground);
}


.content .monaco-action-bar .action-item:hover .action-label,
.content .monaco-action-bar .action-item.checked .action-label {
	opacity: 1;
}

/* Tabs */
.title.tabs {
	background-color: var(--application-editorGroupHeader-tabsBackground);
}

.tab {
	background-color: var(--application-tab-inactiveBackground);
}

.tab-label {
	color: var(--application-tab-inactiveForeground);
}

.tab.active {
	background-color: var(--application-tab-activeBackground);
}

.tab.active .tab-label {
	color: var(--application-tab-activeForeground);
}

.tab:hover {
	/* background-color: red !important; */
	box-shadow: var(--application-tab-unfocusedHoverBorder) 0 -1px inset;
}

.show-file-icons .css-lang-file-icon.file-icon::before, .show-file-icons .css-ext-file-icon.ext-file-icon.file-icon::before {
	content: ' ';
	background-image: url(./icons/css.svg);
}

.show-file-icons .css-lang-file-icon.file-icon::before, .show-file-icons .js-ext-file-icon.ext-file-icon.file-icon::before {
	content: ' ';
	background-image: url(./icons/javascript.svg);
}

.show-file-icons .json-ext-file-icon.ext-file-icon.file-icon::before {
	content: ' ';
	background-image: url(./icons/json.svg);
}

.show-file-icons .package\.json-name-file-icon.json-ext-file-icon.ext-file-icon.file-icon::before, .show-file-icons .package-lock\.json-name-file-icon.json-ext-file-icon.ext-file-icon.file-icon::before, .show-file-icons .\.nvmrc-name-file-icon.nvmrc-ext-file-icon.ext-file-icon.file-icon::before, .show-file-icons .\.esmrc-name-file-icon.esmrc-ext-file-icon.ext-file-icon.file-icon::before {
	content: ' ';
	background-image: url(./icons/nodejs.svg);
}

.show-file-icons .folder-icon::before {
	content: ' ';
	background-image: url(./icons/folder.svg);
}

.show-file-icons .gitignore-ext-file-icon::before {
	content: ' ';
	background-image: url(./icons/git.svg);
}

.show-file-icons .src-name-folder-icon::before {
	content: ' ';
	background-image: url(./icons/folder-src-open.svg);
}

.show-file-icons .node_modules-name-folder-icon::before {
	content: ' ';
	background-image: url(./icons/folder-node.svg);
}

.show-file-icons .package\.json-name-file-icon::before {
	content: ' ';
	background-image: url(./icons/nodejs.svg);
}

.show-file-icons .readme\.md-name-file-icon::before {
	content: ' ';
	background-image: url(./icons/readme.svg);
}

.monaco-workbench {
	color: rgba(255, 255, 255, 0.93); /* set by VSCode I think */
	color: var(--application-foreground);
	background-color: var(--application-background);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}


.monaco-breadcrumbs {
	background-color: var(--application-breadcrumb-background, var(--application-editor-background));
	color: var(--application-breadcrumb-foreground);
}

.monaco-breadcrumb-item:hover {
	color: var(--application-breadcrumb-focusForeground);
}

.monaco-breadcrumb-item:active {
	color: var(--application-breadcrumb-activeSelectionForeground);
}


.monaco-workbench .part > .content {
	height: 100%;
}

.monaco-workbench .part {
	height: 100%;
}

.editor-container {
	background-color: var(--application-editor-background);
	color: var(--application-editor-foreground);
	height: calc(100% - 57px);
}

.monaco-editor,
.monaco-editor-background,
.monaco-editor .inputarea.ime-input {
	background-color: var(--application-editor-background) !important;
	color: var(--application-editor-foreground) !important;
}

.monaco-editor .margin {
	background-color: var(--application-editorGutter-background) !important;
}

.monaco-list-row {
	position: relative;
}


.monaco-list.list_id_2 .monaco-list-row:hover {
	background-color: var(--application-list-hoverBackground);
	outline: 1px dashed var(--application-contrastBorder);
	outline-offset: -1px;
}

/* TODO: need to support active/inactive states based on the focus (on the sidebar or editor) */
.monaco-list.list_id_2 .monaco-list-row.selected {
	background-color: var(--application-list-activeSelectionBackground);
}

.monaco-list.list_id_2:hover .monaco-tl-indent > .indent-guide, .monaco-list.list_id_2.always .monaco-tl-indent > .indent-guide {
	border-color: rgba(88, 88, 88, 0.4);
}


.monaco-list.list_id_2 .monaco-tl-indent > .indent-guide.active {
	border-color: #585858;
}

.indent-guide {
	width: 8px;
}

.monaco-list-row.modified {
	color: var(--application-gitDecoration-modifiedResourceForeground);
}

.monaco-icon-label:after {
	opacity: .75;
	font-size: 90%;
	font-weight: 600;
	padding: 0 16px 0 5px;
	text-align: center;
}

.monaco-list-row.modified .monaco-icon-label:after {
	content: 'M';
}

/* .monaco-tl-twistie { padding-left: 8px; } */

.statusbar-item.has-background-color {
	background-color: var(--application-statusBarItem-remoteBackground);
	color: var(--application-statusBarItem-remoteForeground);
}

.statusbar-item:hover {
	background-color: var(--application-statusBarItem-hoverBackground);
}

.panel {
	/* I think vs code falls back to the editor background if no panel background is set */
	background-color: var(--application-panel-background, var(--application-editor-background));
}

.composite.title {
	border-top-color: var(--application-panel-border);
}

.badge[aria-hidden="true"] { display: none; }

.monaco-action-bar:not(.vertical) .action-item .action-label {
	color: var(--application-panelTitle-inactiveForeground);
}

.monaco-action-bar:not(.vertical) .action-item:hover .action-label {
	color: var(--application-panelTitle-activeForeground);
}

.monaco-action-bar:not(.vertical) .action-item.checked .action-label {
	color: var(--application-panelTitle-activeForeground);
	border-bottom-color: var(--application-panelTitle-activeBorder) !important; /* fuck it */
}

.monaco-select-box {
	background-color: var(--application-dropdown-background);
	border-color: var(--application-dropdown-border);
	color: var(--application-dropdown-foreground);
}

/* .monaco-workbench:focus,
.monaco-workbench:focus-within
.monaco-workbench *:focus {
	outline-color: var(--application-window-activeBorder);
	outline-width: 1px;
	outline-style: solid;
	outline-offset: -1px;
	opacity: 1!important;
} */


.monaco-pane-view .pane>.pane-header h3.title {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 11px;
	-webkit-margin-before: 0;
	-webkit-margin-after: 0;
	line-height: 22px;
}

.monaco-tl-twistie,
.monaco-icon-label::before {
	box-sizing: content-box;
}

.action-item::before {
	display: none !important;
}

.monaco-workbench .part.sidebar > .title > .title-label h2 {
	line-height: inherit;
}

.tab:focus {
	outline: none;
}


.monaco-workbench select {
	font-size: 12px;
}

.monaco-workbench.debug .part.statusbar {
	background-color: var(--application-statusBar-debuggingBackground);
}


.debug-toolbar {
	top: 0;
	left: 50%;
	background-color: var(--application-debugToolBar-background);
}

.monaco-workbench.dark {
	--debugIconStopForeground: #F48771;
	--debugIconDisconnectForeground: #F48771;
	--debugIconPauseForeground: #75BEFF;
	--debugIconStepIntoForeground: #75BEFF;
	--debugIconStepOverForeground: #75BEFF;
	--debugIconStepOutForeground: #75BEFF;
	--debugIconStartForeground: #89D185;
	--debugIconRestartForeground: #89D185;
}

.monaco-workbench.light {
	--debugIconStopForeground: #A1260D;
	--debugIconDisconnectForeground: #A1260D;
	--debugIconPauseForeground: #007ACC;
	--debugIconStepIntoForeground: #007ACC;
	--debugIconStepOverForeground: #007ACC;
	--debugIconStepOutForeground: #007ACC;
	--debugIconStartForeground: #388A34;
	--debugIconRestartForeground: #388A34;
}

.monaco-workbench.hc {
	--debugIconStopForeground: #F48771;
	--debugIconDisconnectForeground: #F48771;
	--debugIconPauseForeground: #75BEFF;
	--debugIconStepIntoForeground: #75BEFF;
	--debugIconStepOverForeground: #75BEFF;
	--debugIconStepOutForeground: #75BEFF;
	--debugIconStartForeground: #89D185;
	--debugIconRestartForeground: #89D185;
}

.codicon-debug-stop {
	color: var(--debugIconStopForeground) !important;
}

.codicon-debug-start {
	color: var(--debugIconStartForeground) !important;
}

.codicon-debug-restart {
	color: var(--debugIconRestartForeground) !important;
}

.codicon-debug-pause {
	color: var(--debugIconPauseForeground) !important;
}

.codicon-debug-step-out {
	color: var(--debugIconStepOutForeground) !important;
}

.codicon-debug-step-into {
	color: var(--debugIconStepIntoForeground) !important;
}

.codicon-debug-step-over {
	color: var(--debugIconStepOverForeground) !important;
}


.monaco-editor .find-widget .button {
	background-color: transparent;
	padding: 0;
	border: none;
	color: inherit;
}


.monaco-workbench select {
	-webkit-appearance: none;
	-moz-appearance: none;
}

.monaco-workbench .select-container {
	position: relative;
}

.monaco-workbench .select-container:after {
	content: "\eab4";
	font-family: codicon;
	font-size: 14px;
	width: 14px;
	height: 14px;
	line-height: 14px;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 6px;
	margin: auto;
	pointer-events: none;
}


.monaco-workbench .part>.title>.title-actions .start-debug-action-item {
	display: flex;
	align-items: center;
	font-size: 11px;
	margin-right: .3em;
	height: 20px;
	flex-shrink: 1;
	margin-top: 7px;
}

.debug-pane .debug-breakpoints .breakpoint.exception {
	padding-left: 20px;
}

.pane-header {
	color: var(--application-sideBarSectionHeader-foreground);
	background-color: var(--application-sideBarSectionHeader-background);
	border-top: 1px solid transparent;
	border-color: var(--application-sideBarSectionHeader-border);
}

.monaco-button {
	
}

.monaco-workbench .input {
	color: var(--application-input-foreground);
	background-color: var(--application-input-background);
	border: 1px solid transparent;
	border-color: var(--application-input-border);
}

.monaco-inputbox > .wrapper > .input {
	border: 1px solid transparent;
	border-color: var(--application-input-border);
}

.monaco-workbench .wrapper .input:focus {
	outline: none;
	border-color: var(--application-focusBorder);
}

.monaco-count-badge {
	padding: 3px 5px;
	border-radius: 11px;
	font-size: 11px;
	min-width: 18px;
	min-height: 18px;
	line-height: 11px;
	font-weight: normal;
	text-align: center;
	display: inline-block;
	box-sizing: border-box;
	background-color: var(--application-badge-background);
	color: var(--application-badge-foreground);
}

.findInFileMatch {
	background-color: var(--application-editor-findMatchHighlightBackground);
}


.scm-viewlet .monaco-list-row .resource>.name.strike-through>.monaco-icon-label>.monaco-icon-label-container>.monaco-icon-name-container>.label-name {
	text-decoration: line-through;
}

.scm-viewlet .scm-editor {
	padding: 6px 12px;
}

.pointer {
	text-decoration: underline;
}

.monaco-action-bar .action-item.disabled .action-label,
.monaco-action-bar .action-item.disabled .action-label:hover {
	opacity: .4;
}


.extension-list-item {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0 0 0 16px;
	overflow: hidden;
	display: flex;
}

.extension-list-item>.details {
	flex: 1;
	padding: 4px 0;
	overflow: hidden;
}

.extension-list-item>.icon-container>.icon {
	width: 42px;
	height: 42px;
	padding: 10px 14px 10px 0;
	flex-shrink: 0;
	object-fit: contain;
	box-sizing: content-box;
}

.extension-list-item>.details>.header-container {
	height: 19px;
	display: flex;
	overflow: hidden;
	padding-right: 11px;
}

.extension-list-item>.details>.header-container>.header {
	display: flex;
	align-items: baseline;
	flex-wrap: nowrap;
	overflow: hidden;
	flex: 1;
	min-width: 0;
}

.extension-list-item>.details>.header-container>.header>.name {
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.extension-list-item>.details>.header-container>.header>.version {
	opacity: .85;
	font-size: 80%;
	padding-left: 6px;
	min-width: fit-content;
	min-width: -moz-fit-content;
	flex: 1;
}

.extension-ratings .codicon-star-full, .extension-ratings .codicon-star-half {
	color: #ff8e00!important;
}

.extension-list-item>.details>.header-container>.header>.install-count:not(:empty) {
	font-size: 80%;
	margin: 0 6px;
}

.extension-list-item>.details>.header-container>.header>.install-count, .extension-list-item>.details>.header-container>.header>.ratings {
	display: flex;
	align-items: center;
}

.extension-list-item .ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.extension-list-item>.details>.footer {
	display: flex;
	justify-content: flex-end;
	padding-right: 7px;
	height: 18px;
	overflow: hidden;
}

.monaco-pane-view .pane>.pane-header>.actions.show {
	display: initial;
}


.monaco-list-row:hover:not(.selected):not(.focused) {
	background-color: var(--application-list-hoverBackground);
	color: var(--application-list-hoverForeground);
}



.monaco-list .monaco-list-row.selected {
	background-color: var(--application-list-focusBackground);
	color: var(--application-list-focusForeground);
}

.monaco-pane-view .split-view-view:first-of-type>.pane>.pane-header {
	border-top: none!important;
}

.extension-bookmark {
	position: absolute;
	display: inline-block;
	height: 20px;
	width: 20px;
}

.extension-bookmark>.recommendation {
	border-right: 20px solid transparent;
	border-top: 20px solid var(--application-button-background);
	box-sizing: border-box;
	position: relative;
	color: var(--application-button-foreground);
}

.extension-bookmark>.recommendation>.codicon {
	position: absolute;
	bottom: 9px;
	left: 1px;
	color: inherit;
	font-size: 80%;
}


.monaco-action-bar .action-item .action-label.extension-action {
	padding: 0 5px;
	outline-offset: 2px;
	line-height: normal;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.extension-list-item>.details>.footer {
	display: flex;
	justify-content: flex-end;
	padding-right: 7px;
	height: 18px;
	overflow: hidden;
}

.extension-list-item>.details>.footer>.author {
	flex: 1;
	font-size: 90%;
	opacity: .9;
	font-weight: 600;
}

.extension-list-item .monaco-action-bar .action-item .action-label.extension-action.label {
	background-color: var(--application-button-background);
	color: var(--application-button-foreground);
}

.extension-list-item .monaco-action-bar .action-item .action-label.extension-action.label:hover {
	background-color: var(--application-button-backgroundHover);
}

.monaco-icon-label:after {
	opacity: .75;
	font-size: 90%;
	font-weight: 600;
	padding: 0 16px 0 5px;
	text-align: center;
}

.monaco-decoration-ignored {
	color: var(--application-gitDecoration-ignoredResourceForeground);
}

.monaco-decoration-deleted {
	color: var(--application-gitDecoration-deletedResourceForeground);
}

.monaco-decoration-modified {
	color: var(--application-gitDecoration-modifiedResourceForeground);
}

.monaco-decoration-untracked {
	color: var(--application-gitDecoration-untrackedResourceForeground);
}

.monaco-decoration-added {
	color: var(--application-gitDecoration-addedResourceForeground);
}

.monaco-decoration-error {
	color: var(--application-list-errorForeground);
}

.monaco-decoration-warning {
	color: var(--application-list-warningForeground);
}

.monaco-decoration-bubbleBadge::after {
	font-family: codicon;
	font-size: 14px;
	padding-right: 14px;
	opacity: 0.4;
}

.monaco-decoration-itemBadge.monaco-decoration-updated::after { content: 'U'; }
.monaco-decoration-itemBadge.monaco-decoration-modified::after { content: 'M'; }
.monaco-decoration-itemBadge.monaco-decoration-added::after { content: 'A'; }
.monaco-decoration-itemBadge.monaco-decoration-deleted::after { content: 'D'; }
.monaco-decoration-itemBadge.monaco-decoration-untracked::after { content: 'U'; }
.monaco-decoration-itemBadge.monaco-decoration-error::after { content: '1,M'; }
.monaco-decoration-itemBadge.monaco-decoration-warning::after { content: '1,M'; }

.monaco-decoration-bubbleBadge.monaco-decoration-updated::after,
.monaco-decoration-bubbleBadge.monaco-decoration-modified::after,
.monaco-decoration-bubbleBadge.monaco-decoration-added::after,
.monaco-decoration-bubbleBadge.monaco-decoration-deleted::after,
.monaco-decoration-bubbleBadge.monaco-decoration-untracked::after,
.monaco-decoration-bubbleBadge.monaco-decoration-error::after,
.monaco-decoration-bubbleBadge.monaco-decoration-warning::after { content: ""; }

.monaco-select-box {
	border-width: 1px;
	border-style: solid;
	width: 100%;
	border-radius: 5px;
	font-size: 11px;
}

.monaco-action-bar .action-item.select-container {
	overflow: hidden;
	flex: 1;
	max-width: 170px;
	min-width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}

.monaco-workbench .part.statusbar.status-border-top:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	pointer-events: none;
	background-color: var(--application-statusBar-border);
	width: 100%;
	height: 1px;
}

.tab-border-top-container,
.tab-border-bottom-container {
	display: block;
	position: absolute;
	left: 0;
	z-index: 6;
	pointer-events: none;
	width: 100%;
	height: 1px;
	background-color: var(--application-tab-activeBorder);
}

.tab-border-top-container {
	top: 0;
	background-color: var(--application-tab-activeBorderTop);
}

.tab-border-bottom-container {
	bottom: 0;
}

.monaco-workbench .part.editor>.content .editor-group-container>.title .tabs-container>.tab.active>.tab-border-bottom-container,
.monaco-workbench .part.editor>.content .editor-group-container>.title .tabs-container>.tab.active>.tab-border-top-container {
	display: block;
}

.monaco-action-bar .action-label {
	font-size: 11px;
}
