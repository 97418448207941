.loading-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: var(--tw-color-shadow-overlay);
	z-index: 9999;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.loading-overlay-message {
	
}
