.toggle-buttons {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: wrap;
	border: var(--tw-border-secondary);
	padding: var(--tw-size-padding-tiny);
	border-radius: var(--tw-size-border-radius);
	background-color: var(--tw-color-background-primary);
	// box-shadow: var(--tw-shadow-secondary-inset);
}

.toggle-buttons.block {
	display: flex;
}

.toggle-button {
	flex: 1;
	display: block;
	padding: var(--tw-size-padding-tiny) var(--tw-size-padding-base);
	font-size: var(--tw-size-font-base);
	// color: var(--tw-color-brand-secondary-1);
	background-color: transparent;
	border: none;
	// border-radius: var(--tw-size-border-radius-xl);
	
	&:hover,
	&:focus {
		// color: var(--tw-color-brand-secondary-2);
		background-color: var(--tw-color-brand-primary-6);
		box-shadow: none;
	}
}

.color-toggle {
	border: none;
}


.color-toggle > .toggle-button {
	margin: 0;
	width: 33.3%;
	flex-basis: 33.3%;
	color: var(--tw-color-font-tertiary);
}

.toggle-button:active,
.toggle-button.active,
.toggle-button:focus {
	background-color: var(--tw-color-brand-primary-1);
	outline: none;
	color: #fff;
}

.toggle-button.red { color: var(--base-red-100); }
.toggle-button.orange { color: var(--base-orange-100); }
.toggle-button.yellow { color: var(--base-yellow-100); }
.toggle-button.lime { color: var(--base-lime-100); }
.toggle-button.green { color: var(--base-green-100); }
.toggle-button.teal { color: var(--base-teal-100); }
.toggle-button.blue { color: var(--base-blue-100); }
.toggle-button.purple { color: var(--base-purple-100); }
.toggle-button.pink { color: var(--base-pink-100); }

.toggle-button.red:hover { background-color: var(--base-red-10); }
.toggle-button.orange:hover { background-color: var(--base-orange-10); }
.toggle-button.yellow:hover { background-color: var(--base-yellow-10); }
.toggle-button.lime:hover { background-color: var(--base-lime-10); }
.toggle-button.green:hover { background-color: var(--base-green-10); }
.toggle-button.teal:hover { background-color: var(--base-teal-10); }
.toggle-button.blue:hover { background-color: var(--base-blue-10); }
.toggle-button.purple:hover { background-color: var(--base-purple-10); }
.toggle-button.pink:hover { background-color: var(--base-pink-10); }

.toggle-button.active { color: #fff; }
.toggle-button.active.red { background-color: var(--base-red-100); }
.toggle-button.active.orange { background-color: var(--base-orange-100); }
.toggle-button.active.yellow { background-color: var(--base-yellow-100); }
.toggle-button.active.lime { background-color: var(--base-lime-100); }
.toggle-button.active.green { background-color: var(--base-green-100); }
.toggle-button.active.teal { background-color: var(--base-teal-100); }
.toggle-button.active.blue { background-color: var(--base-blue-100); }
.toggle-button.active.purple { background-color: var(--base-purple-100); }
.toggle-button.active.pink { background-color: var(--base-pink-100); }
