:root {
	/* Sizes */
	--tw-size-padding-xs: 0.125rem;
	--tw-size-padding-tiny: 0.25rem;
	--tw-size-padding-small: 0.5rem;
	--tw-size-padding-base: 1rem;
	--tw-size-padding-large: 1.5rem;
	--tw-size-padding-xl: 4.5rem;

	--tw-size-border-base: 2px;
	--tw-size-border-large: 4px;
	--tw-size-border-radius-small: 0;
	--tw-size-border-radius: 0.25rem;
	--tw-size-border-radius-large: 1rem;
	--tw-size-border-radius-xl: 10rem;

	--tw-size-font-small: 0.875rem;
	--tw-size-font-base: 1rem;
	--tw-size-font-large: 1.25rem;
	--tw-size-font-xl: 1.75rem;
	--tw-size-font-xxl: 3.5rem;
	
	--tw-size-height-nav: 3rem;

	--tw-time-duration-base: 0.2s;

	--tw-font-family-mono: 'Fira Code', 'Source Code Pro', monospace;
	--tw-font-family-primary: 'Fira Sans', 'Source Sans Pro', 'Open Sans', Arial, Helvetica, sans-serif;
	
	--tw-color-shadow-primary: rgba(0,0,0,0.25);
	--tw-color-shadow-secondary: rgba(0,0,0,0.125);
	--tw-color-shadow-overlay: rgba(255,255,255,0.75);
	
	--tw-color-grey-100: hsl(var(--tw-hue-secondary), 38%, 5%);
	--tw-color-grey-90:  hsl(var(--tw-hue-secondary), 30%, 10%);
	--tw-color-grey-80:  hsl(var(--tw-hue-secondary), 24%, 25%);
	--tw-color-grey-60:  hsl(var(--tw-hue-secondary), 18%, 40%);
	--tw-color-grey-40:  hsl(var(--tw-hue-secondary), 12%, 70%);
	--tw-color-grey-20:  hsl(var(--tw-hue-secondary), 8%, 80%);
	--tw-color-grey-10:  hsl(var(--tw-hue-secondary), 4%, 92%);
	--tw-color-grey-5:   hsl(var(--tw-hue-secondary), 2%, 98%);
	
	// Brand colors
	--tw-hue-primary: 335;
	--tw-hue-secondary: 175;
	--tw-hue-orange: 30;
	--tw-hue-red: 350;
	--tw-hue-green: 150;
	
	--tw-color-brand-primary-1: hsl(
		var(--tw-hue-primary),
		100%,
		45%
	);
	
	--tw-color-brand-primary-2: hsl(
		var(--tw-hue-primary),
		100%,
		55%
	);
	
	--tw-color-brand-primary-3: hsl(
		var(--tw-hue-primary),
		100%,
		85%
	);
	
	--tw-color-brand-primary-6: hsl(
		var(--tw-hue-primary),
		100%,
		95%
	);
	
	--tw-color-brand-secondary-1: hsl(
		var(--tw-hue-secondary),
		100%,
		40%
	);
	
	--tw-color-brand-secondary-2: hsl(
		var(--tw-hue-secondary),
		100%,
		50%
	);
	
	--tw-color-brand-secondary-6: hsl(
		var(--tw-hue-secondary),
		100%,
		95%
	);
	
	--tw-color-background-selection: hsl(
		var(--tw-hue-secondary),
		100%,
		75%,
		25%
	);
	
	--tw-color-outline: hsl(
		var(--tw-hue-primary),
		100%,
		50%,
		0.5
	);
	
	--tw-color-success: hsl(
		var(--tw-hue-green),
		100%,
		40%
	);
	--tw-color-warning: hsl(
		var(--tw-hue-orange),
		100%,
		50%
	);
	--tw-color-danger: hsl(
		var(--tw-hue-red),
		100%,
		60%
	);
	
	// Border colors
	--tw-color-border-primary: var(--tw-color-grey-40);
	--tw-color-border-secondary: var(--tw-color-grey-20);
	--tw-color-border-tertiary: var(--tw-color-grey-10);
	--tw-color-border-hover: var(--tw-color-border-primary);
	--tw-color-border-focus: var(--tw-color-brand-primary-1);
	--tw-color-border-active: var(--tw-color-brand-primary-1);
	
	// Background colors
	--tw-color-background-primary: #fff;
	--tw-color-background-secondary: var(--tw-color-grey-5);
	--tw-color-background-tertiary: var(--tw-color-grey-10);
	--tw-color-background-inverse: var(--tw-color-grey-100);
	
	// Font colors
	--tw-color-font-primary: var(--tw-color-grey-100);
	--tw-color-font-secondary: var(--tw-color-grey-90);
	--tw-color-font-tertiary: var(--tw-color-grey-80);
	--tw-color-font-quaternary: var(--tw-color-grey-60);
	--tw-color-font-link: var(--tw-color-brand-primary-1);
	--tw-color-font-link-hover: var(--tw-color-brand-grey-100);
	--tw-color-font-link-active: var(--tw-color-brand-grey-100);
}


body.dark {
	// Border colors
	--tw-color-border-primary: var(--tw-color-grey-40);
	--tw-color-border-secondary: var(--tw-color-grey-60);
	--tw-color-border-tertiary: var(--tw-color-grey-80);
	// --tw-color-border-hover: var(--tw-color-border-primary);
	// --tw-color-border-focus: var(--tw-color-brand-primary-2);
	// --tw-color-border-active: var(--tw-color-brand-primary-2);
	
	// Background colors
	--tw-color-background-primary: var(--tw-color-grey-100);
	--tw-color-background-secondary: var(--tw-color-grey-90);
	--tw-color-background-tertiary: var(--tw-color-grey-80);
	
	// Font colors
	--tw-color-font-primary: #fff;
	--tw-color-font-secondary: var(--tw-color-grey-10);
	--tw-color-font-tertiary: var(--tw-color-grey-20);
	--tw-color-font-quaternary: var(--tw-color-grey-40);
}

body {
	// Borders
	--tw-border-primary: var(--tw-size-border-base) solid var(--tw-color-border-primary);
	--tw-border-secondary: var(--tw-size-border-base) solid var(--tw-color-border-secondary);
	--tw-border-tertiary: var(--tw-size-border-base) solid var(--tw-color-border-tertiary);
	
	// Shadows
	--tw-shadow-primary: 0 0.25rem 0.5rem var(--tw-color-shadow-primary);
	--tw-shadow-hover: 0.25rem 0.75rem 1rem var(--tw-color-shadow-primary);
	--tw-shadow-hard: 0.25rem 0.25rem var(--tw-color-shadow-secondary);
	--tw-shadow-hard-hover: 0.5rem 0.5rem var(--tw-color-shadow-secondary);
	--tw-shadow-secondary: 0 0 0.25rem var(--tw-color-shadow-secondary);
	--tw-shadow-secondary-inset: 0 0 0.25rem var(--tw-color-shadow-secondary) inset;
	--tw-text-shadow: 0 1px var(--tw-color-shadow-secondary);
	--tw-shadow-thumb: 0 0 0 var(--tw-size-border-large) var(--tw-color-background-primary);
	
	--tw-color-font-nav: var(--tw-color-font-tertiary);
	--tw-color-font-nav-hover: var(--tw-color-font-primary);
	--tw-color-font-nav-active: var(--tw-color-font-primary);
	--tw-color-font-inverse: var(--tw-color-background-primary);
}
