.activitybar {
	width: 48px;
	background-color: var(--application-activityBar-background);
	height: 100%;
}

.activitybar > .content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 13px;
}

.activitybar > .content > .composite-bar {
	margin-bottom: auto;
}

.monaco-action-bar {
	text-align: left;
	overflow: hidden;
	white-space: nowrap;
}

.actions-container {
	display: inline-block;
	margin: 0 auto;
	padding: 0;
	width: 100%;
	justify-content: flex-end;
}

.action-item {
	display: block;
	/* margin-bottom: 4px; */
	position: relative;
	cursor: pointer;
}

/* if activityBar-inactiveForeground is set it is used, if not the 'inactive' state is
	40% opacity */
.activitybar .monaco-action-bar .action-label {
	position: relative;
	z-index: 1;
	display: flex;
	overflow: hidden;
	height: 48px;
	margin-right: 0;
	box-sizing: border-box;
	opacity: 0.4;
	transition: opacity 50ms ease;
	color: var(--application-activityBar-foreground);
}

.activitybar.has-inactive-foreground .action-label {
	opacity: 1;
	color: var(--application-activityBar-inactiveForeground);
}

.content .monaco-action-bar .action-item:hover .action-label,
.content .monaco-action-bar .action-item.checked .action-label {
	opacity: 1;
	color: var(--application-activityBar-foreground);
}

.action-label.codicon {
	font-size: 24px;
	align-items: center;
	justify-content: center;
}

.active-item-indicator:before {
	content: "";
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	border-left: 2px solid transparent;
}

.action-item.checked .active-item-indicator:before {
	border-left-color: var(--application-activityBar-foreground);
}

.monaco-action-bar .badge {
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.monaco-action-bar .badge .badge-content {
	position: absolute;
	top: 24px;
	right: 8px;
	font-size: 9px;
	font-weight: 600;
	min-width: 8px;
	height: 16px;
	line-height: 16px;
	padding: 0 4px;
	border-radius: 20px;
	text-align: center;
	background-color: var(--application-badge-background);
	color: var(--application-badge-foreground);
}
