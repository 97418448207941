.toast {
	display: inline-block;
	padding: var(--tw-size-padding-base);
	border-radius: var(--tw-size-border-radius);
	background-color: var(--tw-color-background-primary);
	border: var(--tw-border-primary);
}

.toast-wrapper {
	position: fixed;
	right: 0;
	bottom: var(--tw-size-padding-base);
	left: 0;
	text-align: center;
	z-index: 99999;
}
