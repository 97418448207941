input:focus {
  outline: none;
}

input[type="file"] {
	-webkit-appearance: none;
	
	&::before {
		content: 'Upload a local theme file';
		display: inline-block;
		background-color: var(--tw-color-brand-primary-1);
		border: none;
		border-bottom: 4px solid var(--tw-color-brand-primary-2);
		color: #fff;
		border-radius: var(--tw-size-border-radius);
		padding: var(--tw-size-padding-small) var(--tw-size-padding-base);
		outline: none;
		white-space: nowrap;
		-webkit-user-select: none;
		cursor: pointer;
		font-size: var(--tw-size-font-base);
		font-weight: bold;
	}
	
	&:hover::before {
		color: #fff;
		border-color: var(--tw-color-brand-primary-2);
		background-color: var(--tw-color-brand-primary-2);
	}
	
	&:active::before {
		background-color: var(--tw-color-brand-primary-2);
	}
}

.file-uploader::-webkit-file-upload-button {
  visibility: hidden;
}

@mixin range-thumb {
	height: 1.5rem;
	width: 1.5rem;
	border-radius: 1.5rem;
	background-color: var(--tw-color-border-primary);
	background-color: transparent;
	cursor: pointer;
	appearance: none;
	box-shadow: var(--tw-shadow-thumb);
	
	&:hover {
		background-color: var(--tw-color-border-hover);
	}
	
	&:active { background-color: var(--tw-color-border-active); }
}

input[type=range] {
  -webkit-appearance: none;
	border: none;
	width: 100%;
	background-color: var(--tw-color-background-primary);
	border-radius: 1.5rem;
	height: 1.5rem;
	
	&.color-range {
		&::-webkit-slider-thumb,
		&::-moz-range-thumb,
		&::-ms-thumb { border: none; }
	}
	
	&:hover { border-color: var(--tw-color-border-hover); }
	// &:active { border-color: var(--tw-color-border-active); }
	
	&::-webkit-slider-runnable-track,
	&::-moz-range-track,
	&::-ms-track {
		width: 100%;
		height: 0.4rem;
		cursor: pointer;
		background-color: var(--theme-font-quaternary);
		background-color: transparent;
		border-radius: 1rem;
	}
	
	&::-webkit-slider-thumb {
		@include range-thumb;
		-webkit-appearance: none;
	}
	
	&::-moz-range-thumb {
		@include range-thumb;
		-moz-appearance: none;
	}
	
	&::-ms-thumb {
		@include range-thumb;
		-moz-appearance: none;
	}
	
	&::-ms-fill-lower {
		background: #ac51b5;
		border: 0px solid #000101;
		border-radius: 50px;
		box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
	}
	
	&::-ms-fill-upper {
		background: #ac51b5;
		border: 0px solid #000101;
		border-radius: 50px;
		box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
	}
	
	&:focus {
		&::-webkit-slider-runnable-track {
			background-color: transparent;
		}
	}
	
	&.hue {
		background: linear-gradient(to right, #f00 0%, #ff0 17%, #0f0
	33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
	}
	
	&.small {
		border-radius: 1rem;
		height: 1rem;
		&::-webkit-slider-thumb {
			height: 1rem;
			width: 1rem;
		}
		&::-moz-range-thumb {
			height: 1rem;
			width: 1rem;
		}
		&::-ms-thumb {
			height: 1rem;
			width: 1rem;
		}
	}
}


.tw-input {
	display: block;
	flex: 1;
	border-width: 0 0 var(--tw-size-border-base) 0;
	border-style: solid;
	border-color: var(--tw-color-border-secondary);
	padding: var(--tw-size-padding-small);
	height: 3rem;
	border-radius: var(--tw-size-border-radius-small);
	font-size: 1rem;
	width: 100%;
	box-sizing: border-box;
	font-family: var(--tw-font-family-mono);
	
	&:invalid {
		border-color: red;
	}
	
	&:hover {
		border-color: var(--tw-color-border-focus);
		// box-shadow: 0 0 0 2px var(--tw-color-border-focus);
	}
	
	&:focus {
		border-color: var(--tw-color-border-focus);
		// box-shadow: 0 0 0 2px var(--tw-color-border-focus);
		// outline: none;
	}
	
	&:active { border-color: var(--tw-color-border-active); }
}


.tw-input-with-button {
	display: flex;
	flex-direction: row;
	margin-bottom: var(--tw-size-padding-base);
	
	& > input {
		flex: 1;
	}
	
	& > button {
		flex-basis: auto;
		flex-grow: 0;
		margin-left: var(--tw-size-padding-tiny);
	}
}
