
button,
.button {
	transition: all 0.3s ease;
	cursor: pointer;
	flex: 1;
	text-align: center;
	padding: var(--tw-size-padding-small) var(--tw-size-padding-base);
	font-size: var(--tw-size-font-base);
	color: var(--tw-color-font-primary);
	background-color: var(--tw-color-background-primary);
	border: var(--tw-size-border-base) solid var(--tw-color-border-secondary);
	font-weight: bold;
	border-radius: var(--tw-size-border-radius);
	
	&:hover,
	&:focus {
		color: var(--tw-color-font-link-hover);
		border-color: var(--tw-color-border-focus);
		box-shadow: 0 0 0 4px var(--tw-color-border-focus);
	}
	
	&:active {
		background-color: var(--tw-color-grey-10);
	}
	
	& > .codicon[class] {
		padding-right: var(--tw-size-padding-tiny);
		font-weight: bold;
		&:only-child {
			padding-right: 0;
		}
	}
}

button.primary,
.button.primary {
	background-color: var(--tw-color-background-inverse);
	border-color: var(--tw-color-background-inverse);
	color: var(--tw-color-font-inverse);
	
	&:hover {
		// box-shadow: var(--tw-shadow-hover);
	}
}

.button.ghost,
button.ghost {
	border: none;
	background: none;
	color: var(--tw-color-font-tertiary);
}

button.danger,
.button.danger {
	color: var(--tw-color-danger);
}

button.block,
.button.block {
	display: block;
	width: 100%;
}

button.large,
.button.large {
	font-size: var(--tw-size-font-large);
	padding: var(--tw-size-padding-large) var(--tw-size-padding-xl);
}

button.small,
.button.small {
	font-size: var(--tw-size-font-small);
	padding: var(--tw-size-padding-tiny) var(--tw-size-padding-small);
}


button.link-button,
.link-button {
	border: none;
	background-color: transparent;
	font-size: inherit;
}
